<script setup lang="ts">
// const props = defineProps<{}>()
// const emit = defineEmits<{}>()
// const { t } = useI18n({ useScope: 'local' })
// const { t: t } = useI18n({ useScope: 'global' })

const { appName } = useAppName()
</script>

<template>
  <div class="col-span-4 flex justify-between">
    <div>{{ appName }} - De online plek om je stages te regelen</div>
    <div class="mt-1 text-sm opacity-50">
      Powered by <a href="https://www.stagedoos.nl" aria-label="Stagedoos website">Stagedoos</a> |
      <NuxtLink to="/privacyverklaring" aria-label="Privacyverklaring">
        Privacy
      </NuxtLink>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>

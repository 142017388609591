// TODO: make this work for Stagedoos as well. So more generic.
// But let's first check the requirements and think about the best way to do this.
// Maybe some site-level footer settings would be best, in the CMS module.
export const useSocialMedia = async () => {
  // const { currentCenterProfile } = await useCurrentCenter()

  const possiblePlatforms = [
    'mastodon',
    'facebook',
    'instagram',
    'tiktok',
    'youtube',
    'x',
  ]

  const getPlatformIconClass = (platform: string) => {
    if (platform == 'mastodon') {
      return 'mdi:mastodon'
    }
    const name = (platform == 'x' ? 'twitter' : platform).toLowerCase()
    return `ph:${name}-logo-fill`
  }

  const platforms = computed(() => {
    return possiblePlatforms
      .map((name) => {
        // This doesn't do anything yet. See volunteerly layer for a working example.
        // We don't have a generic OrganizationProfile type yet.
        // const key = `${name}Url` as keyof any // keyof VolunteerCenterProfile for volunteerly
        // const url = currentCenterProfile.value[key] as string | undefined
        const url = null
        return url ? { name, url } : null
      })
      .filter(Boolean)
  })

  return {
    possiblePlatforms: {},
    getPlatformIconClass,
    platforms,
  }
}

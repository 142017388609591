export default async function useFooterItems() {
  // TODO: fix CMS endpoints for Stagedoos
  // const { parentMenuItems: footer1Items } = await useCmsMenu('footer_1')
  // const { parentMenuItems: footer2Items } = await useCmsMenu('footer_2')
  // const { parentMenuItems: footer3Items } = await useCmsMenu('footer_3')
  // const { parentMenuItems: footer4Items } = await useCmsMenu('footer_4')

  const columns: FooterColumn[] = [
    {
      name: 'Kolom 1',
      // items: [{ name: 'Home', path: '/' }],
      items: [],
    },
    {
      name: 'Kolom 2',
      items: [],
    },
    {
      name: 'Kolom 3',
      items: [],
    },
    {
      name: 'Kolom 4',
      items: [],
    },
  ]

  return { columns }
}
